import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import FullNav from "./full-nav";
import Link from "./link";
import Logo from "./logo";
import ButtonLink from "./button-link";
import SocialNetworks from "./social-networks";
import Ultro from "./ultro";

const query = graphql`
  query {
    pages: allContentfulPage {
      nodes {
        node_locale
        contentful_id
        title
        slug
      }
    }
    allContentfulLink(
      filter: {
        metadata: {
          tags: { elemMatch: { name: { eq: "downloadCatalogFooter" } } }
        }
      }
    ) {
      nodes {
        node_locale
        id
        button
        buttonBackgroundColour
        target
        textColour
        title
        link
      }
    }
  }
`;

const ContactText = () => {
  const { locale } = useIntl();

  if (locale === "en") {
    return (
      <p>
        Any questions about our adventures? Contact us via{" "}
        <Link href="/contact/">this form</Link> or give us a call at{" "}
        <a href="tel:+33652395879">+33 6 52 39 58 79</a>.
      </p>
    );
  }

  return (
    <p>
      Une question sur nos aventures ? Contactez-nous via{" "}
      <Link href="/contact/">notre formulaire</Link> ou appelez-nous au{" "}
      <a href="tel:+33652395879">+33 6 52 39 58 79</a>.
    </p>
  );
};

const Footer = () => {
  const { pages, allContentfulLink } = useStaticQuery(query);
  const { locale, formatMessage } = useIntl();
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const newsletter = formatMessage({ id: "newsletterIntro" });
  const recaptcha = formatMessage({ id: "footerRecaptcha" });

  const getPageById = (id) => {
    const isId = ({ contentful_id }) => contentful_id === id;
    return pages.nodes.filter(isActiveLocale).find(isId);
  };

  const downloadButton = allContentfulLink.nodes.find(
    (item) => item.node_locale === locale
  );

  const privacyPage = getPageById("4MqaAmsD225xUL2r4LOWwj");
  const termsPage = getPageById("4P2nU7ECuj4yDCBZDGPhcU");

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-contact">
            <p>
              <strong>Contact</strong>
            </p>
            <ContactText />
          </div>
          <div id="newsletter" className="footer-newsletter">
            <p>
              <strong>
                <FormattedMessage id="newsletterFooterTitle" />
              </strong>
            </p>
            <p dangerouslySetInnerHTML={{ __html: newsletter }} />
            <div>
              {downloadButton && (
                <ButtonLink
                  href={downloadButton.link}
                  backgroundColor={downloadButton.buttonBackgroundColour}
                  textColor={downloadButton.textColour}
                  button={downloadButton.button}
                  target={downloadButton.target}
                >
                  {downloadButton.title}
                </ButtonLink>
              )}
              <SocialNetworks />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <Link href="/" className="footer-logo">
            <Logo />
          </Link>
          <FullNav />
          <div className="footer-credits">
            <p>
              <span>&copy; Copyright {new Date().getFullYear()}</span>{" "}
              {privacyPage && (
                <>
                  <Link href={`/${privacyPage.slug}/`}>
                    {privacyPage.title}
                  </Link>{" "}
                </>
              )}
              {termsPage && (
                <Link href={`/${termsPage.slug}/`}>{termsPage.title}</Link>
              )}
              <span>
                <FormattedMessage id="footerCredits" />{" "}
                <a href="https://ultro.fr" target="_blank" rel="noreferrer">
                  <Ultro />
                </a>
              </span>
            </p>
            <p dangerouslySetInnerHTML={{ __html: recaptcha }} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
